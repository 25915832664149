.saved-orders {
    .saved-orders-container {
        .result__container {
            border: 1px solid #5B5B5B;
            display: flex;
            padding: 1rem;
            column-gap: 1rem;

            .title {
                font-size: 12px;
                margin: 0;
                line-height: 14px;
                text-align: left;
            }

            .result__data {
                font-weight: 700;
                margin: 0;
                line-height: 21px;
                text-align: left;
            }

            .name {
                min-width: 65px;
                max-width: 65px;
            }

            .item-remove {
                svg {
                    height: 21px;
                }
            }
        }

        .result__container:nth-child(even) {
            border-top: unset;
            background-color: #DCDADB;
        }
    }
}

.restore-saved-order {
    .saved-orders-container {
        margin-top: 2rem;
        display: flex;
        row-gap: 1rem;
        flex-wrap: wrap;
    }

    .result__container {
        flex-basis: 100%;
        border: 1px solid #5B5B5B;
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        row-gap: 1rem;
        column-gap: 1rem;

        .title {
            margin: 0;
            font-size: 12px;
            line-height: 21px;
            text-align: left;
        }

        .result__data {
            margin: 0;
            text-align: left;
            font-weight: bold;
        }

        .part-manufacturer__data {
            font-size: 12px;
            font-weight: 400;
        }

        .part-number {
            flex-basis: 100%;
        }

        .part-description {
            flex-basis: 100%;
        }
    }

    .delete-control {
        margin-top: 2rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .96px;
    }
}

@media screen and (min-width: 768px) {
    .saved-orders {
        padding-left: 6rem;
        padding-right: 6rem;

        .saved-orders-container {
            margin-top: 3rem;
            padding-left: 4rem;
            padding-right: 4rem;

            .result__container {
                .name {
                    min-width: calc(35% - 1rem);
                    max-width: calc(35% - 1rem);
                }

                .timestamp {
                    min-width: calc(30% - 1rem);
                    max-width: calc(30% - 1rem);
                }

                .number {
                    min-width: calc(25% - 1rem);
                    max-width: calc(25% - 1rem);
                }

                .item-remove {
                    min-width: calc(15% - 1rem);
                    max-width: calc(15% - 1rem);
                }
            }
        }
    }

    .restore-saved-order {
        button {
            margin-top: 2rem;
            max-width: 40%;
        }

        .saved-orders-container {
            border: 1px solid #5B5B5B;
            row-gap: 0;

            .saved-orders-header {
                display: flex;
                flex-wrap: wrap;
                padding: 1rem 2rem 0;
                width: 100%;
                column-gap: 1rem;
                position: relative;

                p {
                    text-align: left;
                    margin: 0;
                    font-size: 12px;
                }

                .part-number {
                    min-width: 20%;
                    max-width: 20%;
                }

                .part-description {
                    min-width: 35%;
                    max-width: 35%;
                }

                .quantity {
                    min-width: 7%;
                    max-width: 7%;
                }

                .price {
                    min-width: 18%;
                    max-width: 18%;
                }

                .dealer-price {
                    min-width: 10%;
                    max-width: 10%;
                }
            }

            .result__container {
                border: unset;
                padding: 1rem 2rem;
                column-gap: 1rem;

                .part-number {
                    min-width: 20%;
                    max-width: 20%;
                }

                .part-description {
                    min-width: 35%;
                    max-width: 35%;
                }

                .quantity {
                    min-width: 7%;
                    max-width: 7%;
                }

                .price {
                    min-width: 18%;
                    max-width: 18%;
                }

                .dealer-price {
                    min-width: 10%;
                    max-width: 10%;
                }
            }
        }
    }

    .delete-saved-order {
        padding-left: 11rem;
        padding-right: 11rem;
        .button {
            max-width: 240px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .saved-orders {
        padding-left: 12.6rem;
        padding-right: 12.6rem;
    }

    .restore-saved-order {
        padding: 0 1rem;

        .saved-orders-container {
            .saved-orders-header {



                .part-number {
                    min-width: 16%;
                    max-width: 16%;
                }

                .part-description {
                    min-width: 43%;
                    max-width: 43%;
                }

                .quantity {
                    min-width: 7%;
                    max-width: 7%;
                }

                .price {
                    min-width: 15%;
                    max-width: 15%;
                }

                .dealer-price {
                    min-width: 10%;
                    max-width: 10%;
                }
            }
            .result__container {
                .part-number {
                    min-width: 16%;
                    max-width: 16%;
                }

                .part-description {
                    min-width: 43%;
                    max-width: 43%;
                }

                .quantity {
                    min-width: 7%;
                    max-width: 7%;
                }

                .price {
                    min-width: 15%;
                    max-width: 15%;
                }

                .dealer-price {
                    min-width: 10%;
                    max-width: 10%;
                }
            }
        }
    }

    .delete-saved-order {
        padding-left: 10rem;
        padding-right: 10rem;
        > p {
            margin-bottom: 2rem;
        }
        .button {
            max-width: 340px;
        }
    }

}