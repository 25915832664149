.lightspeed {
    form {
        justify-content: center;
        display: flex;
    }

    .field-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: fit-content;
        margin-bottom: 1rem;
        margin-top: 2rem;
        flex-wrap: wrap;
        .error {
            flex-basis: 100%;
        }
        >span {
            flex-basis: 100%;
        }

        

        .input-container {
            position: relative;
            display: flex;
            justify-content: center;
            width: fit-content;
            height: fit-content;
            overflow: hidden;
            cursor: pointer;
            &:hover {
                label {
                    color: #fff;
                    background-color: #A91108;
                }
            }

            input {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                max-width: 100%;
                cursor: pointer;
                
            }

            label {
                padding: .5rem 1rem;
                border: 1px solid #5B5B5B;
                border-radius: 6px;
                width: 100%;
                cursor: pointer;
                width: calc(85px + 6.59px);
                text-align: center;
            }
        }
    }

    button {
        width: 85px;
        max-width: fit-content;

    }
}