.mini-cart {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 80000;
    width: 100vw;

    &.is-open {
        top: unset;
        left: .5rem;
        bottom: .5rem;
        right: unset;
        width: 95vw;

        .cart-container {
            width: 90%;
            height: 78vh;
            transition: all 1s ease-in-out;
        }
    }

    .icon-container {
        position: absolute;
        padding: 1rem;
        border: 1px solid #000;
        border-radius: 6px;
        background-color: #fff;
        z-index: 50;
        width: fit-content;
        right: 0;
        bottom: 0;

        .count {
            position: absolute;
            top: 25%;
            left: 50%;
            color: #fff;
            font-weight: bold;
        }

        .count.digit__2 {
            left: 45%;
        }

        .count.digit__3 {
            left: 40%;
        }
    }

    .cart-container {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 5;
        width: 10px;
        height: 10px;
        transition: all 1s ease-in-out;
        background-color: #fff;
        border: 1px solid #000;
        padding: 1rem;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;

        .cart-dismiss {
            flex: 1 0 100%;
            display: flex;
            justify-content: flex-end;
        }

        .cart-content {
            margin-top: 1rem;
            max-height: 69vh;
            overflow-y: scroll;
            padding-right: 1rem;
            padding-left: 1rem;

            &::-webkit-scrollbar {
                width: 2px;
                right: -40px;
                /* Position the scrollbar outside the container */
            }

            &::-webkit-scrollbar-track {
                background-color: #cBcBcB;
                /* or any other color */
            }

            &::-webkit-scrollbar-thumb {
                
                background-color: #A91108;
                /* or any other color */
                border-radius: 5px;
                /* rounder corners */
            }

            .result__container {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 2rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid #707070;
                justify-content: flex-start;

                .result {
                    flex: 1 1 auto;

                    p {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }

                .part-number {
                    font-weight: 700;
                    margin-bottom: 1rem;
                    flex-basis: 100%;
                }

                .part-description {
                    font-weight: 700;
                    margin-bottom: 1rem;
                    flex-basis: 100%;

                    .part-manufacturer__data {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                .price {
                    font-weight: 700;
                    min-width: 110px;
                    max-width: 110px;

                    .discount__data {
                        margin-top: 10px;
                        font-size: 12px;
                        max-width: 100px;

                        span {
                            color: #A91108;
                            font-weight: 700;
                        }
                    }
                }

                .quantity {
                    width: fit-content;

                    &:hover {

                        .inc,
                        .dec {
                            opacity: 1;
                        }
                    }

                    .input {
                        padding: .5rem 1rem;
                        border-radius: 6px;
                        border: 1px solid #707070;
                        position: relative;
                        width: fit-content;
                        .value {
                            border: unset;
                            width: fit-content;
                            max-width: 36px;
                            padding: 0;
                            margin: 0;
                        }
                    }

                    .inc {
                        position: absolute;
                        top: 2px;
                        right: 3px;
                        //opacity: 0;
                        //display: none;

                        &:hover {
                            color: #A91108;
                        }
                    }

                    .dec {
                        position: absolute;
                        bottom: 2px;
                        right: 3px;
                        //opacity: 0;
                        //display: none;

                        &:hover {
                            color: #A91108;
                        }
                    }

                }

                .subtotal {
                    color: #A91108;
                    font-weight: 700;
                }

                .item-remove {
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .mini-cart {
        &.is-open {
            top: unset;
            left: unset;
            bottom: 0.5rem;
            right: 0.5rem;
            width: 95vw;
            max-width: 370px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .mini-cart {
        &.is-open {
            max-width: 1000px;
            .cart-container {
                height: fit-content;
            }
        }

        .cart-container {
            .cart-content {
                max-height: 30vh;
                width: 100%;
                column-gap: 2rem;

                .result__container {
                    flex-wrap: nowrap;

                    .result {
                        flex: 1 0 auto;
                        justify-content: flex-start;
                    }

                    .part-number {
                        flex-basis: 17%;
                    }

                    .part-description {
                        flex-grow: 2;
                        flex-basis: 40%;
                    }

                    .price {
                        flex-basis: 15%;
                        max-width: 150px;
                        min-width: 150px;

                        .discount__data {
                            max-width: 140px;
                        }
                    }

                    .quantity {
                        flex-basis: 10%;
                    }

                    .subtotal {
                        flex-basis: 12.5%;
                    }

                    .item-remove {
                        flex-basis: 12.5%;
                    }
                }
            }
        }
    }
}