.search-bar {
    margin-bottom: 1.5rem;

    button {
        background-color: #A91108;
        color: #fff;
        border: 1px solid #A91108;
        border-radius: 0 6px 6px 0px;
        text-transform: uppercase;
        font-size: 16px;
        padding: .5rem 1rem;
        font-weight: bold;
    }

    input {
        padding: .5rem 1rem;
        border-radius: 6px 0px 0px 6px;
        border: 1px solid #000;
    }
}



.search-results {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    justify-content: center;
    form {
        row-gap: 0;
    }

    .search-results__stats {
        flex: 1 1 100%;
    }
    .result__container {
        flex: 1 1 100%;
    }

    .search-results__stats {
        margin-bottom: 1rem;

        .pagination-selector {
            display: flex;
            column-gap: 1rem;
            justify-content: center;

            select {
                border: unset;
                border-bottom: 1px solid #000;
                //-webkit-appearance: none;
                background-color: initial;
                border-radius: 0;
                color: #000;
                background-color: unset;

                &::after {
                    content: "⌄";
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
    &.basic {
        .result__container {
            &:nth-child(n+2) {
                border-top: 1px solid #5B5B5B;
            }
            &:nth-child(n+3 ) {
                border-top: unset;
            }
        }
    }
    .result__container {
        display: flex;
        border: 1px solid #5B5B5B;
        justify-content: space-between;
        padding: 1rem;
        text-align: left;

        &:nth-child(odd) {
            background-color: #DCDADB;
        }

        &:nth-child(n+2) {
            border-top: unset;
        }


        flex-wrap: wrap;
        row-gap: 1rem;

        >* {
            flex-basis: 100%;
        }

        .result {
            .result__label {
                font-size: 12px;
                font-weight: 400;
                margin: 0;
            }

            .result__data {
                margin: 0;
                font-weight: 700;
                font-size: 16px;
            }
        }

        .quantity {
            span {
                color: #A91108;
            }
        }
        .part-description {
            .part-manufacturer__data {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        column-gap: 2rem;

        p {
            color: #A91108;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 21px;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: #000;
            }
        }

        p.disabled {
            color: #DCDADB;
            cursor: unset;

            &:hover {
                text-decoration: unset;
            }
        }

        .prev {
            &::before {
                content: '<';
            }
        }

        .next {
            &::after {
                content: ' >';
            }
        }
    }
}

.stock-order {
    .search-results {

        .result__container {
            .dealer_price {
                flex: 0 1 auto;
            }
            .quantity-selector {
                width: fit-content;
                margin-bottom: 1rem;
                flex: 0 1 auto;
                margin-bottom: 0;
                width: 140px;
                display: flex;
                justify-content: flex-end;
                &:hover {
                    .inc, .dec {
                        opacity: 1;
                    }
                }
                .input {
                    padding: .5rem 1rem;
                    //padding-right: 1.75rem;
                    border-radius: 6px;
                    border: 1px solid #707070;
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    .value {
                        border: unset;
                        width: fit-content;
                        max-width: 36px;
                        padding: 0;
                        margin: 0;
                        background-color: transparent;
                    }
                }
                .inc {
                    position: absolute;
                    top: 2px;
                    right: 3px;
                    // opacity: 0;
                    // display: none;
                    &:hover {
                        color: #A91108;
                    }
                }
                .dec {
                    position: absolute;
                    bottom: 2px;
                    right: 3px;
                    // opacity: 0;
                    // display: none;
                    &:hover {
                        color: #A91108;
                    }
                }
            }
            .add-to-cart {
                flex:  0 1 auto;
                min-width: 30px;
                margin-top: 5px;
                svg {
                    min-width: 30px;
                    height: 100%;
                }
            }
        }
    }
    .loading {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: unset;
    }
}


@media screen and (min-width:768px) {
    .search-results {
        .result__container {
            flex-wrap: nowrap;

            .part-number {
                flex-basis: 20%;
                max-width: 20%;
            }

            .part-description {
                flex-basis: 40%;
                max-width: 40%;
            }

            .quantity {
                flex-basis: 20%;
                max-width: 20%;

                span {
                    color: #A91108;
                }
            }

            .price {
                flex-basis: 20%;
                max-width: 20%;
            }
        }
    }

    .stock-order {
        .search-results {
            .result__container {
                column-gap: 1rem;
                flex-wrap: wrap;
                justify-content: flex-end;
                row-gap: 0;
                .part-number  {
                    flex: 1 1 auto;
                    min-width: calc(15% - 1rem);
                    max-width: calc(15% - 1rem);
                }
                .part-description {
                    flex:  1 1 auto;
                    min-width: calc(42% - 1rem);
                    max-width: calc(42% - 1rem);
                }
                .quantity {
                    flex: 1 1 auto;
                    min-width: calc(12% - 1rem);
                    max-width: calc(12% - 1rem);
                }
                .price {
                    flex: 1 1 auto;
                    min-width: calc(20% - 1rem);
                    max-width: calc(20% - 1rem);
                }
                .dealer_price {
                    flex: 1 1 auto;
                    min-width: calc(13% - 1rem);
                    max-width: calc(13% - 1rem);
                }
                .quantity-selector {
                    flex: 1 1 auto;
                    min-width: calc(10% - 1rem);
                    max-width: calc(10% - 1rem);
                }
                .add-to-cart {
                    flex: 1 1 auto;
                    min-width: calc(5% - 1rem);
                    max-width: calc(5% - 1rem);
                    align-items: center;
                    display: flex;
                    //margin-top: unset;
                    svg {
                        min-width: 100%;
                    }
                }
            }
        }
    }
}


@media screen and (min-width:1024px) {
    .stock-order {
        .search-results {
            .result__container {
                column-gap: 1rem;
                flex-wrap: wrap;
                .part-number  {
                    flex: 1 1 auto;
                    min-width: calc(16.6% - 1rem);
                    max-width: calc(16.6% - 1rem);
                }
                .part-description {
                    flex:  1 1 auto;
                    min-width: calc(30% - 1rem);
                    max-width: calc(30% - 1rem);
                }
                .quantity {
                    flex: 1 1 auto;
                    min-width: calc(15% - 1rem);
                    max-width: calc(15% - 1rem);
                }
                .price {
                    flex: 1 1 auto;
                    min-width: calc(15% - 1rem);
                    max-width: calc(15% - 1rem);
                }
                .dealer_price {
                    flex: 1 1 auto;
                    min-width: calc(10% - 1rem);
                    max-width: calc(10% - 1rem);
                }
                .quantity-selector {
                    flex: 1 1 auto;
                    min-width: calc(10% - 1rem);
                    max-width: calc(10% - 1rem);
                }
                .add-to-cart {
                    flex: 1 1 auto;
                    min-width: calc(5% - 1rem);
                    max-width: calc(5% - 1rem);
                    display: flex;
                    align-items: flex-start;
                    //margin-top: unset;
                    svg {
                        min-width: auto;
                        max-width: 30px;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:1440px) {
    .stock {
        padding-left: 8rem;
        padding-right: 8rem;
    }
    .stock-order {
        .search-results {
            .result__container {
                column-gap: 1rem;
                flex-wrap: wrap;
                .part-number  {
                    flex: 1 1 auto;
                    min-width: calc(15% - 1rem);
                    max-width: calc(15% - 1rem);
                }
                .part-description {
                    flex:  1 1 auto;
                    min-width: calc(32% - 1rem);
                    max-width: calc(32% - 1rem);
                }
                .quantity {
                    flex: 1 1 auto;
                    min-width: calc(15% - 1rem);
                    max-width: calc(15% - 1rem);
                }
                .price {
                    flex: 1 1 auto;
                    min-width: calc(15% - 1rem);
                    max-width: calc(15% - 1rem);
                }
                .dealer_price {
                    flex: 1 1 auto;
                    min-width: calc(10% - 1rem);
                    max-width: calc(10% - 1rem);
                }
                .quantity-selector {
                    flex: 1 1 auto;
                    min-width: calc(5% - 1rem);
                    max-width: calc(5% - 1rem);
                }
                .add-to-cart {
                    flex: 1 1 auto;
                    min-width: calc(5% - 1rem);
                    max-width: calc(5% - 1rem);
                    //margin-top: unset;
                    svg {
                        min-width: auto;
                        max-width: 30px;
                        * {
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2); /* Adjust scale value to control how much it grows */
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pulse-animation {
    animation: pulse 0.5s ease forwards; /* Adjust time as needed */
    transform-origin: center; /* Ensure it grows from the center */
  }