.finances {
    .total-container {
        border: 1px solid #000;
        margin-bottom: 4rem;
        .title {
            margin-bottom: 2px;
            font-size: 12px;
            line-height: 21px;
        }
        .total {
            font-size: 2rem;
            color: #A91108;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 1.75rem;
            
        }
    }
    .label {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 700;
        text-align: left;
        margin-bottom: 0.875rem;
    }
    .amount-due-container {
        border: 1px solid #000;
        padding: 1rem;
        margin-bottom: 4rem;
        .current-container{
            width: 100%;
            box-sizing: border-box;
            background-color: #DCDADB;
            padding: 1rem;
            display: flex;
            justify-content: center;
            .title {
                margin-right: 1.375rem;
                font-size: 0.75rem;
                line-height: 1.3125rem;
            }
            .value {
                font-size: 1rem;
                line-height: 1.3125rem;
                font-weight: 700;
            }

        }
        .period-container {
            display: flex;
            flex-wrap: wrap;
            gap: 1.125rem;
            margin-top: 1.125rem;
            .period {
                flex-basis: calc(50% - 0.5625rem);
                box-sizing: border-box;
                padding: 0.5rem;
                justify-content: center;
                display: flex;
                flex-wrap: wrap;
                max-width: calc(50%  - 0.5625rem);
                background-color: #DCDADB;
                align-items: center;
                &.red {
                    background-color: #A91108;
                    color: #fff;
                }
                .title {
                    font-size: 0.75rem;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    flex-basis: 100%;
                }
                .value {
                    display: flex;
                    flex-basis: 100%;
                    justify-content: center;
                    font-weight: 700;
                }
            }
        }
        .legend {
            display: flex;
            font-size: 0.75rem;
            line-height: 1.3125rem;
            font-style: italic;
            align-items: center;
            margin-top: 1.5rem;
            margin-bottom: 0.5rem;
            &::before {
                content: "";
                height: 1rem;
                width: 1rem;
                background-color: #A91108;
                display: flex;
                margin-right: 1ch;
      
            }
        }
    }

    .account-info-container {
        margin-bottom: 4rem;
        > div {
            border: 1px solid #000;
            text-align: left;
            padding: 1rem;
            p {
                margin-top: 0;
                margin-bottom: 0.5rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:not(:first-child) {
                border-top: 1px solid transparent;
                
            }
        }
        .suspended {
            background-color: #A91108;
            color: #fff;
        }

    }
}

@media screen and (min-width:768px) {
    .finances {
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;
        .total-container {
            margin-top: 2rem;
            max-width: 23.4375rem;
            margin-left: auto;
            margin-right: auto;
        }
        .amount-due-container {
            padding-left: 2rem;
            padding-right: 2rem;
            .period-container {
                .period {
                    flex-basis: calc(25% - 0.84375rem);
                    flex-wrap: nowrap;
                    justify-content: center;
                    column-gap: 1ch;
                    .title {
                        flex-basis: auto;
                        flex-grow: 0;
                        flex-shrink: 1;
                    }
                    .value {
                        flex-basis: auto;
                        flex-grow: 0;
                        flex-shrink: 1;
                    }

                }
            }
        }

        .account-info-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            > div { 
                flex: 1 1 auto;
                &:not(:first-child) {
                    border-top: 1px solid #000;
                    border-left: 1px solid transparent;
                    
                }
            }
        }
    }
}


    
