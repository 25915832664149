.home {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4rem;
    .info {
        display: flex;
        order: 2;
        flex-wrap: wrap;
        border: 1px solid #000;
        border-radius: 6px;
        padding: 2rem;
        row-gap: 1rem;
        text-align: left;
        > * {
            flex-basis: 100%;
        }
        p {
            margin: 0;
        }
        .link-wrapper {
            justify-content: flex-start;
        }

    }
    .home-order, .home-suspended {
        display: flex;
        order: 1;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 1rem;
        align-content: center;
        h2 {
            text-transform: uppercase;
            margin: 0; 
            max-width: 70%;
        }
        > * {
            flex-basis: 100%;
        }
        p {
            margin: 0;
        }

    }
}

@media screen and (min-width: 768px) {
    .home {
        padding-left: 12rem;
        padding-right: 12rem;
    }
}

@media screen and (min-width: 1024px) {
    .home {
        padding-left: unset;
        padding-right: unset;
        flex-wrap: nowrap;
        column-gap: 2rem;
        .info {
            order: 1;
            flex-basis: calc(50% - 2rem);
        }
        .home-order, .home-suspended {
            order: 2;
            flex-basis: calc(50% - 2rem);
            h2 {
                max-width: 60%;
            }
            p {
                max-width: 75%;

            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .home {
        padding-left: 6rem;
        padding-right: 6rem;
        column-gap: 6rem;
        .home-order {
            h2 {
                max-width: 50%;
            }
            p {
                max-width: 60%;
            }
        }
    }
    
}

@media screen and (min-width: 1920px) {
    .home {
        padding-left: 16rem;
        padding-right: 16rem;
        column-gap: 16rem;
        .home-order {
            h2 {
                max-width: 100%;
            }
            p {
                max-width: 60%;
            }
        }
    }
    
}