.app-footer {
    .footer-main {
        display: flex;
        background-color: #DCDADB;
        column-gap: 2rem;
        padding: 1rem;
        flex-wrap: wrap;
        row-gap: 1rem;
        .footer-logo {
            flex: 1 1 100%;
            img {
                max-width: 219px;
            }
        }

        .footer-blurb {
            flex: 1 1 100%;
            font-size: 16px;
            display: flex;
            flex-wrap: wrap;
            align-content: baseline;
            margin-bottom: 1.5rem;
            p {
                margin-top: 0;
            }

            .button {
                background-color: #A91108;
                padding: .5rem .75rem;
                border-radius: 6px;
                color: #fff;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: .96px;
                line-height: 21px;
                font-weight: 700;
                width: fit-content;
                flex-basis: unset;
                &:hover {
                    background-color: #000;
                }
            }
        }

        .footer-contact {
            flex: 1 1 100%;
            > div:not(:last-child) {
                margin-bottom: .5rem;
            }
            p,
            a {
                margin: 0;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
            }

            a {
                color: #A91108;
            }

            .has-icon {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                color: #A91108;

                &:hover {

                    svg,
                    a {
                        color: #000;
                    }
                }
            }
        }
    }
    .footer-utility {
        display: flex;
        padding: 1rem 2rem;
        justify-content: center;
        font-size: 12px;
        flex-wrap: wrap;
        p {
            margin: 0;
        }
        .footer-longevity {
            a {
                color: #A91108;
                &:hover {
                    color: #000;
                }
            }
        }
    }
}

@media screen and (min-width:768px) { 
    .app-footer {
        .footer-main {
            flex-wrap: wrap;
            .footer-logo {
                flex: 0 1 10%
            }
            .footer-blurb {
                flex:  1 0 35%;
                margin-bottom: unset;
            }
            .footer-contact {
                flex:  1 0 25%;
                
            }
        }
        .footer-utility {
            justify-content: space-between;
        }
    }
}


@media screen and (min-width: 1920px) {
    .app-footer {
        .footer-main {
            padding: 1rem 2rem;
            .footer-logo {
                flex: 1 1 20%;
            }
            .footer-blurb {
                flex: 1 1 50%;
                font-size: 16px;
                flex-wrap: nowrap;
                flex-direction: column;
                 p {
                    max-width: 65%;
                 }
                 .button {
                    max-width: fit-content;
                    width: fit-content;
                 }
            }
            .footer-contact {
                flex: 1 1 25%;
                
                p,
                a {
                    margin: 0;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                }
    
                a {
                    color: #A91108;
                }
    
                .has-icon {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    color: #A91108;
    
                    &:hover {
    
                        svg,
                        a {
                            color: #000;
                        }
                    }
                }
            }
        }
        .footer-utility {
            display: flex;
            padding: 1rem 2rem;
            justify-content: space-between;
            font-size: 12px;
            p {
                margin: 0;
            }
            .footer-longevity {
                a {
                    color: #A91108;
                    &:hover {
                        color: #000;
                    }
                }
            }
        }
    }
}