.cart {
    .cart-container {
        margin-top: 2rem;

        .cart-content {
            display: flex;
            flex-wrap: wrap;
            border-top: 1px solid #707070;
            padding-top: 1rem;
            min-width: 100%;

            .result__container {
                display: flex;
                flex-basis: 100%;
                justify-content: space-evenly;
                flex-wrap: wrap;
                padding-bottom: 1rem;
                border-bottom: 1px solid #707070;
                margin-bottom: 1rem;
                min-width: 100%;
                .result {
                    flex: 1 0 auto;
                    text-align: left;

                    p {
                        margin: 0;
                    }
                }

                .part-number {
                    font-weight: 700;
                    margin-bottom: 1rem;
                    flex-basis: 100%;
                }

                .part-description {
                    font-weight: 700;
                    margin-bottom: 1rem;
                    flex-basis: 100%;

                    .part-manufacturer__data {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                .price {
                    font-weight: 700;
                    min-width: 110px;
                    max-width: 110px;
                    margin-bottom: 1rem;

                    .discount__data {
                        margin-top: 10px;
                        font-size: 12px;
                        max-width: 100px;

                        span {
                            color: #A91108;
                            font-weight: 700;
                        }
                    }
                }

                .quantity {
                    width: fit-content;
                    margin-bottom: 1rem;

                    &:hover {

                        .inc,
                        .dec {
                            opacity: 1;
                        }
                    }

                    .input {
                        padding: .5rem;
                        padding-right: 1.75rem;
                        border-radius: 6px;
                        border: 1px solid #707070;
                        position: relative;
                        width: fit-content;
                        .value {
                            border: unset;
                            width: fit-content;
                            max-width: 36px;
                            padding: 0;
                            margin: 0;
                        }
                    }

                    .inc {
                        position: absolute;
                        top: 2px;
                        right: 3px;
                        opacity: 0;

                        &:hover {
                            color: #A91108;
                        }
                    }

                    .dec {
                        position: absolute;
                        bottom: 2px;
                        right: 3px;
                        opacity: 0;

                        &:hover {
                            color: #A91108;
                        }
                    }

                }

                .subtotal {
                    color: #A91108;
                    font-weight: 700;
                }

                .note {
                    flex-basis: 80%;
                    width: auto;
                    //margin-top: 1rem;
                    column-gap: 1rem;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;

                    p {
                        font-size: 12px;
                        flex-shrink: 2;
                        width: fit-content;
                    }

                    input {
                        flex-grow: 2;
                        padding: 4px;
                        border-radius: 6px;
                        border: 1px solid #707070;
                    }

                    >* {
                        max-width: 50%;
                        flex: 1 1 calc(50% - 2rem);
                    }
                }

                .item-remove {
                    flex-basis: 20%;

                    svg {
                        margin-top: 4px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }

        }

        .cart-totals {
            flex: 1 1 100%;
            display: flex;
            flex-wrap: wrap;
            column-gap: 1rem;
            row-gap: 1rem;
            margin-top: 1.5rem;
            margin-bottom: 4rem;

            .price-info {
                display: flex;
                flex-wrap: wrap;
                row-gap: 1rem;
                flex-basis: calc(60% - 1rem);

                p {
                    flex-basis: 100%;
                }
            }

            p {
                margin: 0;
                text-align: left;
            }

            p.shipping-info {
                font-weight: 700;

                span {
                    color: #A91108;
                }
            }

            p.small {
                font-size: 12px;
                flex-basis: calc(40% - 1rem);
            }

            p.price {
                color: #A91108;
                font-weight: 700;
            }
        }

        .cart-buttons {
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            row-gap: 1rem;
            margin-bottom: 3rem;

            .button-primary {
                display: flex;
                align-items: center;

                span {
                    margin-right: 1ch;
                }

                svg {
                    margin-top: 1px;
                }
            }
        }

    }
}


@media screen and (min-width: 768px) {
    .cart {
        .cart-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .cart-content {
                .result__container {
                    column-gap: 2rem;

                    .part-number {
                        flex-basis: auto;
                    }

                    .part-description {
                        flex-basis: 75%;
                    }

                    .price {
                        margin-bottom: unset;
                        flex-basis: auto;
                    }

                    .quantity {
                        margin-bottom: unset;
                    }

                    .note {
                        flex-basis: auto;
                        align-items: flex-start;

                        p {
                            display: flex;
                            height: 29px;
                            align-items: center;
                            flex: 0 1 auto;
                        }

                        input {
                            display: flex;
                            flex-basis: auto;
                            flex: 0 1 auto;
                        }
                    }

                    .item-remove {
                        flex-basis: auto;
                    }
                }


            }

            .cart-totals {
                max-width: 50%;
                justify-content: center;
                display: flex;
                flex-wrap: wrap;

                p.small {
                    flex-basis: calc(50% - 1rem);
                }

                .price-info {
                    flex-basis: calc(50% - 1rem);
                }
            }

            .cart-buttons {
                max-width: 40%;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .cart {
        .cart-container {

            .cart-content {
                .result__container {
                    column-gap: .5rem;
                    flex-wrap: nowrap;

                    .part-number {
                        flex-basis: 15%;
                        min-width: 15%;
                        max-width: 15%;
                    }

                    .part-description {
                        flex-basis: 25%;
                        min-width: 25%;
                        max-width: 25%;
                    }

                    .price {
                        flex-basis: 8%;
                        min-width: 8%;
                        max-width: 8%;
                    }

                    .quantity {
                        flex-basis: 7%;
                        min-width: 7%;
                        max-width: 7%;

                        .input {
                            margin-top: -8px;
                            input.value {
                                display: flex;
                                max-width: 40px;
                                -webkit-appearance: none;
                                appearance: none;
                                margin: 0;
                            }
                        }

                        
                    }

                    .subtotal {
                        flex-basis: 9%;
                        min-width: 9%;
                        max-width: 9%;
                    }

                    .note {
                        flex-basis: 25%;
                        min-width: 25%;
                        max-width: 25%;
                        align-items: flex-start;
                        margin-top: -4px;

                        p {
                            display: flex;
                            height: 29px;
                            align-items: center;
                            flex: 0 1 auto;
                        }

                        input {
                            display: flex;
                            flex-basis: auto;
                            flex: 0 1 auto;
                        }
                    }

                    .item-remove {
                        flex-basis: 1%;
                        min-width: 1%;
                        max-width: 1%;
                        margin-top: -3px;
                    }
                }
            }

            .cart-totals {
                justify-content: center;
                margin-bottom: 2rem;

                .small {
                    display: flex;
                    justify-content: flex-end;
                    text-align: right;

                }

                >div {
                    flex-basis: 100%;
                    justify-content: center;
                }
            }

            .cart-buttons {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .cart {
        padding-left: 4rem;
        padding-right: 4rem;
        .cart-container {
             width: 100%;
        }
    }
}

@media screen and (min-width: 1920px) {
    .cart {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}