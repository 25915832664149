body {
    font-family: 'Lato', sans-serif;

}



main {
    min-height: 30vh;
    padding-left: 2rem;
    padding-right: 2rem;
    //margin-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-content: center;
    position: relative;

    > * {
        flex:  1 1 100%;
    }

    hr {
        max-width: 7.5%;
    }

    .uppercase {
        text-transform: uppercase;
    }


    .loading {
        p { white-space: nowrap;}
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 3rem;
    }
    .error {
        p {
            white-space: pre;
        }
    }


 
}

@media screen and (min-width:768px) {
    main {
        min-height: 42vw;
    }
}

@media screen and (min-width:1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
    main {
        min-height: 79vw;
    }
}

@media screen and (min-width: 1024px) {
    main {
        //margin-top: 2rem;
        padding-bottom: 2rem;
    }
}

@media screen and (min-width: 1440px) {
    main {
        min-height: 52vh;
    }
}

@media screen and (min-width: 1920px) {

    main {
        min-height: 60vh;
    }
}