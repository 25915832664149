.invoice-history {
    .invoice-container {
        .invoice {
            display: flex;
            flex-wrap: wrap;
            border: 1px solid #5B5B5B;
            row-gap: 1rem;
            padding: 1rem 1rem;
            justify-content: space-evenly;
            &:not(:first-child) {
                border-top: unset;
            }
            &:nth-child(even) {
                background-color: #DCDADB;
            }
            .data {
                text-align: left;
                flex-basis: calc(50% - 1rem);
                .title {
                    font-size: 12px;
                    margin: 0;
                    white-space: nowrap;
                }
                .content {
                    font-weight: 700;
                }
                p {
                    margin: 0;
                    
                }
            }
        }
    }
}

.invoice-history-detail {
    margin-left: -1rem;
    margin-right: -1rem;
    p {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .link-wrapper {
        display: none;
        .link-container  {
            a {
                text-transform: uppercase;
                font-weight: 700;
            }
            .icon {
              svg {
                width: 19px;
                height: 19px;
                margin-top: 1px;
              }  
            }
        }
        
    }
    .address-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 1rem;
        justify-content: center;
    }
    .invoice-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 1rem;
        margin-bottom: 1rem;
        .part {
            border: 1px solid #000;
            width: 100%;
            padding: 2rem;
            display: flex;
            flex-wrap: wrap;
            // column-gap: 1rem;
            .result {
                .result__data {
                    font-weight: 700;
                }
            }
            p {
                text-align: left;
                
                &.subtotal__data {
                    margin-bottom: 0;
                }
                
            }
            .part-number {
                .title {
                    margin-top: 0;
                }
            }
            .part-description {
                flex-basis: 100%;
            }

            .price {
                flex-basis: 65%;
            }
            .dealer-price {
                flex-basis: 35%;
                &.no-retail {
                    flex-basis: 100%;
                }
            }
            .quantity {
                flex-basis: 33%;
                .title {
                    max-width: 70%;
                }
            }
            .quantity_shipped {
                flex-basis: 33%;
                .title {
                    max-width: 70%;
                }
            }
            .quantity_backordered {
                flex-basis: 33%;
            }
            .subtotal {
                flex-basis: 35%;
            }

        }
        .invoice-header {
            display: flex;
        }
        .result__container {
            display: flex;
        }
    }
    .box-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
        .cost-container {
            border: 1px solid #000;
            display: flex;
            flex-basis: 100%;
            padding: 2rem;
            display: flex;
            flex-wrap: wrap;
            row-gap: 1rem;
            .cost {
                flex-basis: 100%;
                text-align: left;
                display: flex;
                align-items: center;
                .title {
                    flex-basis: 40%;
                    font-size: 0.75rem;
                    line-height: 1.3125rem;
                }
                .value {
                    flex-basis: 60%;
                    color: #A91108;
                    font-weight: 700;
                    line-height: 1.125rem;
                }
            }
        }
        .total-container {
            border: 1px solid #000;
            padding: 1rem;
            display: flex;
            flex-basis: 100%;
            flex-wrap: nowrap;
            text-align: left;
            .title {
                margin-top: .8rem;
                flex-basis: calc(40% - 1rem);
                padding-left: 1rem;
                font-size: 0.75rem;
            }
            .value {
                flex-basis: 60%;
                display: flex;
                flex-wrap: wrap;
                > span {
                    flex-basis: 100%;
                    
                }
                .amount {
                    font-size: 2rem;
                    color: #A91108;
                    font-weight: 700;
                }
                .due {
                    margin-top: 1rem;
                    font-size: 0.75rem;
                }
            }
        }
    }
    
    
}

@media all and (min-width: 768px) {
    .invoice-history {
        .invoice-container {
            padding-left: 6rem;
            padding-right: 6rem;
            .invoice {
                .data {
                    flex-basis: calc(25% - 1rem);
                }
            }
        }
    }
    .invoice-history-detail {
        // margin-left: 0;
        // margin-right: 0;
        .link-wrapper {
            display: flex;
            margin-bottom: 2rem;
        }
        .address-container {
            display: flex;
            justify-content: center;
            column-gap: 4rem;
        }
        .invoice-container {
            border: 1px solid #000;
            padding: 1rem;
            .invoice-header {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                text-align: left;
                font-size: 0.75rem;
                > * {
                    display: flex;
                    align-items: flex-end;
                }
                .part-number {
                    min-width: 12%;
                    max-width: 12%;
                }
                .part-description {
                    min-width: 28%;
                    max-width: 28%;
                }
                .price {
                    min-width: 18%;
                    max-width: 18%;
                }
                .dealer-price {
                    min-width: 15%;
                    max-width: 15%;
                }
                .quantity {
                    min-width: 9%;
                    max-width: 9%;
                }
                .quantity_shipped {
                    min-width: 8%;
                    max-width: 8%;
                }
                .quantity_backordered {
                    min-width: 12%;
                    max-width: 12%;
                }
                .subtotal {
                    min-width: 11%;
                    max-width: 11%;
                }
            }
            .result__container {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                text-align: left;
                font-weight: 700;
                .part-number {
                    min-width: 12%;
                    max-width: 12%;
                }
                .part-description {
                    min-width: 28%;
                    max-width: 28%;
                    .part-description__data {
                        padding-right: 1rem;
                    }
                }
                .price {
                    min-width: 18%;
                    max-width: 18%;
                }
                .dealer-price {
                    min-width: 15%;
                    max-width: 15%;
                }
                .quantity {
                    min-width: 9%;
                    max-width: 9%;
                }
                .quantity_shipped {
                    min-width: 8%;
                    max-width: 8%;
                }
                .quantity_backordered {
                    min-width: 12%;
                    max-width: 12%;
                }
                .subtotal {
                    min-width: 11%;
                    max-width: 11%;
                }
            }
        }
        .box-container {
            display: flex;
            justify-content: center;
            column-gap: 1.25rem;
            flex-wrap: nowrap;
            .cost-container {
                border: 1px solid #000;
                display: flex;
                flex-basis: 70%;
                justify-content: space-evenly;
                .cost {
                    flex-basis: auto;
                    column-gap: 1rem;
                    .title {
                        white-space: pre;

                    }
                }
            }
            .total-container {
                border: 1px solid #000;
                display: flex;
                flex-basis: 30%;
                flex-wrap: wrap;
                .title, .value {
                    flex-basis: 100%;
                    text-align: center;
                    .due {
                        margin-top: .5rem;
                    }
                }
            }
        }
    }

   
}

@media all and (min-width: 1024px) {
    .invoice-history {
        .invoice-container {
            padding-left: 10rem;
            padding-right: 10rem;
        }
    }
    .invoice-history-detail {
        margin-left: 0;
        margin-right: 0;
    }
}

@media all and (min-width: 1440px) {
    .invoice-history-detail {
        max-width: 1024px;
    }
}
