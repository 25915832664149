@media screen and (min-width: 768px) {
    .dealer-confirm {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

@media screen and (min-width: 1024px) {
    .dealer-confirm {
        padding-left: 12rem;
        padding-right: 12rem;
    }
}