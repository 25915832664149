
@media screen and (min-width: 768px) {
    .forgot-password, .change-password, .change-email {
        padding-left: 13rem;
        padding-right: 13rem;
    } 

}


@media screen and (min-width: 1024px) {
    .forgot-password, .change-password, .change-email {
        padding-left: 20rem;
        padding-right: 20rem;
    } 
}

@media screen and (min-width: 1440px) {
    .forgot-password, .change-password, .change-email {
        padding-left: 30rem;
        padding-right: 30rem;
    } 
}

@media screen and (min-width: 1920px) {
    .forgot-password, .change-password, .change-email {
        padding-left: 45rem;
        padding-right: 45rem;
    } 
}