main.login, main.forgot-password {
    background-image: url("../../public/background.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 140px;
    .login-container, .form-container {
        background-color: #fff;
        border-radius: 6px;
        padding: 2rem 1rem;
        h1 {
            margin-top: 0;
        }
        .login-form {
            form {
                display: flex;
                flex-wrap: wrap;
                row-gap: 1rem;
                margin-bottom: 1rem;
                .field-container {
                    display: flex;
                    flex-wrap: wrap;
                    flex: 1 1 100%;
                    label, input {
                        flex: 1 1 100%;
                    }
                    label {
                        text-align: left;
                        margin-bottom: .5rem;
                    }
                    input {
                        border: 1px solid #000;
                        border-radius: 6px;
                        padding: .5rem;
                    }
                    .error {
                        color: #A91108;
                        font-weight: bold;
                        margin-bottom: .5rem;
                    }
                    .error ~ input {
                        border-color: #A91108;

                    }
                }
                button {
                    flex: 1 1 100%;
                    background-color: #A91108;
                    border: 1px solid #A91108;
                    color: #fff;
                    text-transform: uppercase;
                    padding: .5rem 2rem;
                    border-radius: 6px;
                    &:hover {
                        background-color: #000;
                        border-color: #000;
                    }
                }
            }

        }
    }
}

@media screen and (min-width: 768px) {
    main.login, main.forgot-password {
        padding-left: 12rem;
        padding-right: 12rem;
        .login-container, .form-container {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }
    }
}

@media screen and (min-width: 1024px) {
    main.login, main.forgot-password {
        padding-left: 18rem;
        padding-right: 18rem;
    }
}

@media screen and (min-width: 1440px) {
    main.login, main.forgot-password {
        padding-left: 30rem;
        padding-right: 30rem;
    }
}

@media screen and (min-width: 1920px) {
    main.login, main.forgot-password {
        padding-left: 48rem;
        padding-right: 48rem;
    }
}