.toast {
  transition: all ease-in-out 1s;
  opacity: 0;
  transform: scale(0.7);
  background-color: #fff;
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid #000;
  max-width: 360px;
  .toast-header {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 1rem;
    position: relative;
    button {
      border: unset;
      background: unset;
      position: absolute;
      top: -15px;
      right: -15px;
    }
  }
  .toast-body {
    a {
      color: #A91108;
      text-decoration: none;
      padding-bottom: 2px;
      border-bottom: 1px solid #A91108;
    }
  }
}

.toast.animate-in {
  opacity: 1;
  transform: scale(1);
}


.toast.danger {
  .toast-header {
    color: #A91108;
    border-bottom: unset;
  }
  button {
    color: #A91108;
  }
}

.toast.success {
  border-color: #5cb85c;
  .toast-header {
    color: #5cb85c;
    border-bottom: unset;
  }
  button {
    color: #5cb85c;
  }
}

@keyframes bounce {
    // 0%, 100% {
    //   transform: scale(1);
    // }
    50% {
      transform: scale(1.02);
    }
  }


$intensity: 8;

@keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50%50%;
        transform-origin: 50%50%;
      }
      7.5% {
        -webkit-transform: translateX(calc(-30px / $intensity))rotate(calc(-6deg / $intensity));
        transform: translateX(calc(-30px / $intensity))rotate(calc(-6deg / $intensity));
      }
      15% {
        -webkit-transform: translateX(calc(15px / $intensity))rotate(calc(6deg / $intensity));
        transform: translateX(calc(15px / $intensity))rotate( calc( 6deg / $intensity));
      }
      22.5% {
        -webkit-transform: translateX(calc( -15px / $intensity))rotate( calc(-3.6deg / $intensity));
        transform: translateX(calc(-15px / $intensity ))rotate(calc( -3.6deg / $intensity));
      }
      30% {
        -webkit-transform: translateX(calc(9px / $intensity))rotate(calc(2.4deg / $intensity));
        transform: translateX(calc(9px / $intensity))rotate(calc(2.4deg / $intensity));
      }
      37.5% {
        -webkit-transform: translateX(calc(-6px / $intensity))rotate(calc(-1.2deg / $intensity));
        transform: translateX(calc( -6px / $intensity) )rotate(calc( -1.2deg / $intensity));
      }
      100% {
        -webkit-transform: translateX(calc(-6px / $intensity))rotate(0deg);
        transform: translateX(calc(-6px / $intensity))rotate(0deg);
      }
}


@keyframes custom-enter {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes custom-exit {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}