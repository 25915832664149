.checkout-container {
    .active {
        display: flex;
    }
    .hidden {
        display: none;
    }

    form {
        label {
            margin-bottom: .5rem;
        }
        .section-1 {
            flex-wrap: wrap;
            row-gap: 2rem;
            .field-container {
                flex-basis: 100%;
            }
            .ship-via {
                display: flex;
                .input-container {
                    display: flex;
                    column-gap: 4rem;
                    .radio-container {
                        display: flex;
                        input[type="radio"] {
                          display: none;
                        }
                      
                        label {
                          margin: 0;
                          display: inline-block;
                          width: 12px; // diameter of the circle
                          height: 12px; // diameter of the circle
                          border-radius: 50%; // create a circle
                          border: 1px solid #000; // set the border
                          background-clip: content-box;
                          padding: 2px;
                          max-width: 12px;
                          cursor: pointer;
                          margin-right: 1ch;
                        }
                        input[type="radio"]:checked + label {
                          background-color: #A91108; // change the background color when selected
                        }
                      }
                    
                }
                .error {
                    flex-basis: 100%;
                    text-align: left;
                }
            }
        }
        .section-2 {
            flex-wrap: wrap;
            row-gap: 2rem;
            .field-container {
                flex-basis: 100%;
            }
            .address-data {
                text-align: left;
                p {
                    margin: 0;
                    line-height: 21px;
                }
                p.bold {
                    margin-bottom: .5rem;
                }
            }
        }

    }

    .button-group {
        flex-basis: 100%;
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        .link-wrapper {
            flex-basis: calc(50% - .5rem);
            width: calc(50% - .5rem);
            .link-container {
                width: 100%;
            }
        }
        button {
            flex-basis: calc(50% - .5rem);
            width: calc(50% - .5rem);
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-weight: 700;
            letter-spacing: 0.96px;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 0.5rem;
            svg {
                margin-top: 1px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .checkout-container {
        padding-left: 12rem;
        padding-right: 12rem;
    }
}

@media screen and (min-width: 1024px) {
    .checkout-container {
        padding-left: 18rem;
        padding-right: 18rem;
    }
}

@media screen and (min-width: 1440px) {
    .checkout-container {
        padding-left: 24rem;
        padding-right: 24rem;
    }
}

@media screen and (min-width: 1920px) {
    .checkout-container {
        padding-left: 40rem;
        padding-right: 40rem;
    }
}