.app-header {
    .main-nav {
        width: 100%;
        max-width: 100%;
        background-color: #5B5B5B;
        padding: 1.125rem;
        display: flex;
        justify-content: flex-start;

        p {
            margin: 0;
            color: #fff;

            a {
                color: #fff;
                &:hover {
                    color: #000;
                }
            }
        }

        &.none {
            .logged-out-menu {
                display: flex;
                list-style-type: none;
                justify-content: space-between;
                width: 100%;
                padding-inline-start: 0;
                font-size: 18px;
                margin: 0;

                li {
                    color: #fff;
                    display: flex;
                    column-gap: .5rem;
                    align-items: center;

                    &:hover {
                        color: #000 !important;

                        a {
                            color: #000;
                            border-color: #000;
                        }
                    }

                    a {
                        color: #fff;
                        text-decoration: none;
                        font-weight: bold;
                        border-bottom: 1px solid white;
                        padding-bottom: 2px;
                    }
                }
            }
        }

        &:not(.none) {
            display: flex;
            justify-content: space-between;

            .main-nav-container {
                position: fixed;
                width: 100vw;
                height: 100%;
                top: 0;
                left: 0;
                background-color: #5B5B5B;
                color: #fff;
                display: flex;
                align-content: center;
                align-items: flex-start;
                transform: translateX(-100%);
                transition: transform 0.45s ease-in-out;
                z-index: 9999;
                justify-content: center;
                .mobile-nav-close {
                    position: absolute;
                    z-index: 9999;
                    top: 1rem;
                    left: 1rem;
                    width: 48px;
                    height: 48px;
                }

                .return {
                    z-index: 9999;
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    display: flex;
                    justify-content: flex-end;
                    column-gap: 1rem;

                    &:hover {
                        a {
                            color: #000 !important;
                        }

                        svg {
                            color: #000 !important;
                        }
                    }

                    p {
                        width: 50%;
                        text-transform: uppercase;
                        text-align: right;

                        a {
                            text-decoration: none;
                            font-weight: 700;
                            border: unset;
                        }
                    }

                    .mobile-nav-catalog {
                        width: 44px;
                        height: auto;

                    }
                }

                ul {
                    margin-top: 12rem;
                    list-style-type: none;
                    display: flex;
                    row-gap: 2rem;
                    flex-wrap: wrap;
                    text-align: center;
                    padding-inline-start: 0;

                    li {
                        flex: 1 1 100%;
                        font-size: 18px;
                        a {
                            text-decoration: none;
                            padding-bottom: 2px;
                            border-bottom: 1px solid #fff;
                        }
                        &:hover {
                            a {
                                color: #000;
                                border-color: #000;
                            }
                        }
                    }
                }

                a {
                    color: #fff;
                }
            }
            .main-nav-container.open {
                transform: translateY(0);
                z-index: 9999;
            }
            .mobile-nav-open-container {
                flex: 1 1 1%;
                justify-content: flex-start;
                align-items: center;
                display: flex;
                .mobile-nav-open {
                    width: 32px;
                    height: auto;
                }
            }
            .controls {
                flex: 1 1 60%;
                justify-content: flex-end;
                overflow: visible;
                ul {
                    list-style-type: none;
                    padding-inline-start: 0;
                    display: flex;
                    justify-content: flex-end;
                    column-gap: 1rem;
                    margin-bottom: 0;
                    margin-top: .5rem;
                    li {
                        display: flex;
                        column-gap: .5rem;
                        font-size: 18px;
                        align-items: center;
                        cursor: pointer;
                        &:hover {
                            a, svg {
                                color: #000;
                                border-color: #000;
                            }
                            
                        }
                        a {
                            text-decoration: none;
                            padding-bottom: 2px;
                            text-transform: uppercase;
                            font-weight: bold;
                            border-bottom: 1px solid #fff;
                            color: #fff;
                        }
                        a.name {
                            width: fit-content;
                            max-width: 200px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }

        }
    }
}

@media screen and (min-width: 768px) {
    .app-header {
        .main-nav:not(.none) {
            .mobile-nav-open-container {
                flex: 1 1 20%;
            }
            .controls {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                column-gap: 1rem;
                .dealer-selector {
                    width: 100%;
                }
                ul {
                    column-gap: 1rem;
                    margin-top: 0;
                    li {
                        a.name {
                            width: fit-content;
                        }
                    }
                   
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .app-header {
        .main-nav.none  {
            padding: 1.125rem 3rem;
        }
        .main-nav:not(.none) {
            padding: 1.125rem 2rem;
            max-width: 100vw;
            column-gap: 1rem;
            &.superUser, &.admin {
                display: flex;
                flex-wrap: wrap;
                row-gap: 1rem;
                .main-nav-container {
                    height: auto;
                    justify-content: flex-start;
                }
                .controls {
                    flex: 1 1 auto;
                    justify-content: flex-start;
                    .dealer-selector {
                        width: 350px;
                    }
                }
            }
            .main-nav-container {
                transform: unset;
                position: relative;
                width: auto;
                flex: 1 1 60%;
                align-items: baseline;
                .mobile-nav-close {
                    display: none;
                    
                }
                ul {
                    margin-top: 0;
                    margin-bottom: 0;
                    flex-wrap: nowrap;
                    column-gap: 1rem;
                    padding-inline-start: 0;
                    li {
                        flex: 1 1 auto;
                        a {
                            white-space: nowrap;
                        }
                        
                    }
                }
                
            }
            .controls {
                flex: 1 1 15%;
                flex-wrap: wrap; 
                ul {
                    margin-top: .25rem;
                    li {
                        a.name {
                            max-width: 250px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:1440px) {
    .app-header {
        .main-nav:not(.none) {
            &.superUser, &.admin {
                display: flex;
                flex-wrap: nowrap;
                row-gap: 1rem;
                .main-nav-container {
                    height: 100%;
                    flex: 1 1 70%;
                    justify-content: flex-start;
                }
                .controls {
                    flex: 1 1 15%;
                    justify-content: flex-end;
                    .dealer-selector {
                        width: 250px;
                    }
                }
            }
            .main-nav-container {
                flex: 1 1 70%;
                justify-content: flex-start;
                ul {
                    margin-bottom: 0;
                }
                
            }
            .controls {
                display: flex;
                flex-wrap: nowrap;
                column-gap: 2rem;
                flex: 1 1 30%;
                align-items: center;
                .dealer-selector {
                    flex: 1 1 60%;
                }
                ul {
                    flex: 1 1 40%;
                    margin-top: 0;
                    justify-content: flex-end;
                    column-gap: 2rem;
                    li {
                        a.name {
                            max-width: 400px;
                        }
                    }
                }
            }
        }
    }
}