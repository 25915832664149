.header-container {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100%;
    transition: all .3s ease-in-out;
    overflow: visible;
    z-index: 9999;
}

.app-header {
    display: flex;
    flex-wrap: wrap;
    overflow: visible;

    .header-main {
        background-color: #A91108;
        border-bottom: 8px solid #000;
        width: 100%;
        padding: 1rem 2rem;
        display: flex;
        flex-wrap: wrap;
        overflow: visible;

        .logo-column {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            order: 2;
            justify-content: center;

            h1 {
                text-transform: uppercase;
                color: #fff;
                margin: 0;
                font-size: 24px;

                &::after {
                    display: none;
                }

                span {
                    padding-left: 1ch;
                    padding-right: 1ch;
                    display: none;
                }

                hr {
                    width: 15%;
                    border-top: 2px solid;
                }
            }
        }

        .nav-column {
            order: 1;
            display: flex;
            justify-content: flex-end;
            //align-items: center;
            flex: 1 1 auto;
            text-transform: uppercase;
            flex-wrap: nowrap;
            margin-top: -1.25rem;
            margin-right: -1.25rem;

            .nav:hover {
                color: #000;

                p {
                    a {
                        color: #000;
                    }
                }

                svg {
                    color: #000;
                }
            }

            .nav {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                p {
                    width: 50%;
                    text-align: right;

                    a {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 700;
                        text-decoration: none;
                        width: 80%;
                    }

                }

                svg {
                    color: #fff;
                    width: 40px;
                    height: 40px;
                    margin-left: 1rem;
                }
            }



        }

    }
    .suspended-bar {
        width: 100%;
        background-color: #000;
        padding: 1rem;
        display: flex;
        justify-content: center;
        a {
            color: #fff;
            border-bottom: 1px solid #fff;
            text-transform: uppercase;
            font-size: 1.125rem;
            &:hover {
                color: #A91108;
                border-bottom: 1px solid #A91108;
            }
            
        }
    }


}

@media screen and (min-width:768px) {
    .app-header {
        .header-main {
            flex-wrap: nowrap;

            .logo-column {
                order: unset;
                flex-wrap: nowrap;

                h1 {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    flex-direction: row;
                    flex-basis: auto;
                    flex-wrap: nowrap;

                    hr {
                        display: none;
                    }

                    span {
                        display: flex;
                    }
                }

            }

            .nav-column {
                order: unset;
                margin-top: unset;
                margin-right: unset;

                .nav {

                    p {
                        width: 60%;
                    }

                }

                p {
                    width: 65%;
                }

                svg {
                    margin-left: .5rem;
                }
            }
        }

        .main-nav {
            p {
                width: 75%;
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .app-header {
        .main-nav {
            p {
                width: 10%;
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    .app-header {
        .header-main {
            .logo-column {
                h1 {
                    font-size: 30px;
                }
            }

            .nav-column {
                p {
                    width: 11%;
                }
            }
        }

        .main-nav {
            p {
                width: 45%
            }
        }
    }

}