.open-orders {
    .order {
        display: flex;
        border: 1px solid #5B5B5B;
        padding: 1rem 1.5rem;
        justify-content: space-evenly;
        margin-left: -1rem;
        margin-right: -1rem;

        &:not(:first-child) {
            border-top: unset;
        }

        &:nth-child(even) {
            background-color: #DCDADB;
        }

        .title {
            font-size: 12px;
            line-height: 21px;
            margin: 0;
        }

        .result {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .result__data {
            font-weight: 700;
        }

        .order__date {
            min-width: 30%;
            max-width: 30%;
        }

        .order__po {
            min-width: 48%;
            max-width: 48%;
        }

        .order__status {
            min-width: 30%;
            max-width: 30%;
        }

        .delete {
            align-items: center;
            cursor: pointer;
            &:hover {
                svg {
                    color: #000 !important;
                }
                
            }
        }


        .result__status {
            text-transform: capitalize;
        }

        p,
        a {
            margin: 0;
            text-align: left;
        }
    }
}

.open-order {
    >p {
        margin: 0;
        margin-bottom: .25rem;
    }

    .part-manufacturer__data {
        font-size: 12px;
        font-weight: 400;
    }

    .part {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #5B5B5B;
        padding: 2rem 1.5rem;
        row-gap: 1rem;
        column-gap: 1rem;
        margin-bottom: 1rem;

        .part-number,
        .part-description {
            flex-basis: 100%;
        }

        .result {
            text-align: left;

            .title {
                font-size: 12px;
                line-height: 21px;
                margin: 0;
            }



            .result__data {
                margin: 0;
                font-weight: 700;
            }

            .part-manufacturer__data {
                font-size: 12px;
                font-weight: 400;
            }

            .backorder__data {
                color: #A91108;
                display: flex;
                align-items: center;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .open-orders {
        padding-left: 8rem;
        padding-right: 8rem;

        .orders-container {
            padding-left: 4rem;
            padding-right: 4rem;
            margin-top: 2rem;

            .order {
                justify-content: center;
                padding-left: 4rem;
                padding-right: 4rem;

                .result {
                    flex-basis: 50%;

                    >p {
                        flex-basis: 100%;

                    }
                }
            }
        }
    }

    .open-order {
        .order-container {
            border: 1px solid #5B5B5B;
            margin-bottom: 2rem;

            &.pending-shipment {
                .open-order-header {
                    .backorder {
                        display: none;
                    }

                    .part-description {
                        min-width: 23%;
                        max-width: 23%;
                    }

                    .quantity {
                        min-width: 7%;
                        max-width: 7%;
                    }

                    .quantity_shipped {
                        min-width: 7%;
                        max-width: 7%;
                    }

                    .quantity_backordered {
                        min-width: 10%;
                        max-width: 10%;
                    }
                }

                .result__container {
                    .backorder {
                        display: none;
                    }

                    .part-description {
                        min-width: 23%;
                        max-width: 23%;
                    }

                    .quantity {
                        min-width: 7%;
                        max-width: 7%;
                    }

                    .quantity_shipped {
                        min-width: 7%;
                        max-width: 7%;
                    }

                    .quantity_backordered {
                        min-width: 10%;
                        max-width: 10%;
                    }
                }
            }

            .open-order-header {
                display: flex;
                flex-wrap: wrap;
                padding: 1rem 2rem 0;
                column-gap: 1rem;
                position: relative;

                p {
                    text-align: left;
                    margin: 0;
                    font-size: 12px;
                }

                .part-number {
                    min-width: 18%;
                    max-width: 18%;
                }

                .part-description {
                    min-width: 25%;
                    max-width: 25%;
                }

                .price {
                    min-width: 10%;
                    max-width: 10%;
                }

                .dealer-price {
                    min-width: 10%;
                    max-width: 10%;
                }

                .quantity {
                    min-width: 7%;
                    max-width: 7%;
                }

                .backorder {
                    min-width: 18%;
                    max-width: 18%;
                }

            }

            .result__container {
                display: flex;
                border: unset;
                padding: 1rem 2rem;
                column-gap: 1rem;
                flex-wrap: wrap;
                text-align: left;
                font-weight: 700;

                .result {
                    .result__data {
                        margin: 0;
                    }
                }

                .part-number {
                    min-width: 18%;
                    max-width: 18%;
                }

                .part-description {
                    min-width: 25%;
                    max-width: 25%;
                }

                .quantity {
                    min-width: 7%;
                    max-width: 7%;
                }

                .price {
                    min-width: 10%;
                    max-width: 10%;
                }

                .dealer-price {
                    min-width: 10%;
                    max-width: 10%;
                }

                .backorder {
                    min-width: 18%;
                    max-width: 18%;

                    .backorder__data {
                        color: #A91108;
                        display: flex;
                        align-items: center;
                    }
                }

            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .open-orders {
        padding-left: 8rem;
        padding-right: 8rem;

        .orders-container {
            padding-left: 4rem;
            padding-right: 4rem;
            margin-top: 2rem;

            .order {
                justify-content: center;
                padding-left: 4rem;
                padding-right: 4rem;
                margin-left: 0;
                margin-right: 0;

                .result {
                    flex-basis: 50%;

                    //justify-content: center;
                    >p {
                        flex-basis: 100%;

                    }
                }

                .order__date {
                    min-width: 33%;
                    max-width: 33%;
                }

                .order__po {
                    min-width: 33%;
                    max-width: 33%;
                }

                .order__status {
                    min-width: 33%;
                    max-width: 33%;
                }
            }
        }
    }

    .open-order {
        padding-left: 2rem;
        padding-right: 2rem;

        .order-container {
            &.pending-shipment {
                .open-order-header {
                    .part-description {
                        min-width: 20%;
                        max-width: 20%;
                    }
                }
                .result__container {
                    .part-description {
                        min-width: 20%;
                        max-width: 20%;
                    }
                }
            }

            .open-order-header {
                .part-number {
                    min-width: 18%;
                    max-width: 18%;
                }

                .part-description {
                    min-width: 24%;
                    max-width: 24%;
                }

                .quantity {
                    min-width: 7%;
                    max-width: 7%;
                }

                .price {
                    min-width: 16%;
                    max-width: 16%;
                }

                .dealer-price {
                    min-width: 10%;
                    max-width: 10%;
                }

                .backorder {
                    min-width: 15%;
                    max-width: 15%;
                }
            }

            .result__container {
                .part-number {
                    min-width: 18%;
                    max-width: 18%;
                }

                .part-description {
                    min-width: 24%;
                    max-width: 24%;
                }

                .quantity {
                    min-width: 7%;
                    max-width: 7%;
                }

                .price {
                    min-width: 16%;
                    max-width: 16%;
                }

                .dealer-price {
                    min-width: 10%;
                    max-width: 10%;
                }

                .backorder {
                    min-width: 15%;
                    max-width: 15%;
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .open-orders {
        padding-left: 18rem;
        padding-right: 18rem;
    }
}

@media screen and (min-width: 1920px) {
    .open-orders {
        padding-left: 28rem;
        padding-right: 28rem;
    }
}