.save-cart {
    p {
        margin-bottom: 2rem;
    }
}

.order-saved {
    p {
        margin-bottom: 2rem;
    }
}

@media screen and (min-width:768px) {
    .save-cart {
        padding-left: 13rem;
        padding-right: 13rem;
        form {
            margin-left: 3rem;
            margin-right: 3rem;
        }
    }

    .order-saved {
        padding-left: 13rem;
        padding-right: 13rem;

        button {
            max-width: 70%;
        }
    }
}

@media screen and (min-width:1024px) {
    .save-cart {
        padding-left: 8.5rem;
        padding-right: 8.5rem;
        form {
            margin-left: 12rem;
            margin-right: 12rem;
        }
    }

    .order-saved {
        padding-left: 11rem;
        padding-right: 11rem;

        button {
            max-width: 40%;
        }
    }
}

@media screen and (min-width:1440px) {
    .save-cart {
        padding-left: 21.5rem;
        padding-right: 21.5rem;
    }


}

@media screen and (min-width:1920px) {
    .save-cart {
        padding-left: 36.5rem;
        padding-right: 36.5rem;
    }


}


