.dealer-list {
    .dealer-list {
        padding: unset;
    }
    .dealer-container {
        .dealer {
            border: 1px solid #5B5B5B;
            text-align: left;
            padding: 1rem;
            display: flex;
            row-gap: 1rem;
            flex-wrap: wrap;

            &:nth-child(odd) {
                background-color: #DCDADB;
            }

            &:nth-child(n+3) {
                border-top: unset;
            }

            .result {
                flex-basis: 100%;

                .title {
                    font-size: 12px;
                    margin-bottom: .25rem;
                }

                .result__data {
                    font-weight: 700;
                }
            }

            .address {
                .address__data {
                    p {
                        margin: 0;
                        line-height: 21px;
                    }
                }
            }

            .last-order,
            .last-date {
                flex-basis: 50%;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .dealer-list {
        .dealer-container {
            .dealer-header {
                display: flex;
                margin: 1rem;
                flex-wrap: wrap;

                .title {
                    font-size: 12px;
                    margin-bottom: .25rem;
                    text-align: left;
                }

                .number {
                    min-width: 10%;
                    max-width: 10%;
                }

                .name {
                    min-width: 20%;
                    max-width: 20%;
                }

                .address {
                    min-width: 30%;
                    max-width: 30%;
                }

                .last-login {
                    min-width: 10%;
                    max-width: 10%;
                }

                .last-order {
                    min-width: 17%;
                    max-width: 17%;
                }

                .last-date {
                    min-width: 13%;
                    max-width: 13%;
                }

            }

            .dealer {
                &:nth-child(n+2) {
                    border-top: 1px solid #5B5B5B;
                }

                &:nth-child(n+3) {
                    border-top: unset;
                }

                .result {
                    flex-basis: auto;
                }

                .number {
                    min-width: 10%;
                    max-width: 10%;
                }

                .name {
                    min-width: 20%;
                    max-width: 20%;
                }

                .address {
                    min-width: 30%;
                    max-width: 30%;
                }

                .last-login {
                    min-width: 11%;
                    max-width: 11%;
                }

                .last-order {
                    min-width: 15%;
                    max-width: 15%;
                }

                .last-date {
                    min-width: 13%;
                    max-width: 13%;
                }
            }
        }
    }

}

@media screen and (min-width: 1024px) {
    .dealer-list {
        padding-left: 1rem;
        padding-right: 1rem;

        >div>p {
            margin-bottom: 2rem;
        }

        .dealer-container {
            .dealer-header {
                .number {
                    min-width: 8%;
                    max-width: 8%;
                }

                .name {
                    min-width: 20%;
                    max-width: 20%;
                }

                .address {
                    min-width: 37%;
                    max-width: 37%;
                }

                .last-login {
                    min-width: 10%;
                    max-width: 10%;
                }

                .last-order {
                    min-width: 15%;
                    max-width: 15%;
                }

                .last-date {
                    min-width: 10%;
                    max-width: 10%;

                }

            }

            .dealer {

                .number {
                    min-width: 8%;
                    max-width: 8%;
                }

                .name {
                    min-width: 20%;
                    max-width: 20%;
                }

                .address {
                    min-width: 37%;
                    max-width: 37%;

                    .address__data {
                        p {
                            display: inline;

                            &:first-child {
                                margin-right: 4px;
                            }

                        }
                    }
                }

                .last-login {
                    min-width: 10%;
                    max-width: 10%;
                }

                .last-order {
                    min-width: 15%;
                    max-width: 15%;
                }

                .last-date {

                    min-width: 10%;
                    max-width: 10%;

                }

            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .dealer-list {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}

@media screen and (min-width: 1920px) {
    .dealer-list {
        padding-left: 16rem;
        padding-right: 16rem;
    }
}