.order-summary {
    p.bold {
        margin: 0;
        margin-bottom: .5rem;
    }

    p.company-name {
        margin-bottom: 2rem;
    }

    .cart-accordion {
        .accordion__item {
            border: 1px solid #5B5B5B;
            margin-bottom: 2rem;

            .accordion__heading {
                padding: 2rem 1rem;

                &.expanded {
                    padding: 2rem 1rem 1rem;
                }

                .accordion__button {
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    svg {
                        width: 25px;
                        height: auto;
                    }

                    span {
                        font-weight: 700;
                    }
                }
            }

            .accordion__panel:not([hidden]) {
                padding: 0 2rem 1rem;
                display: flex;
                flex-wrap: wrap;
                column-gap: 1rem;
                row-gap: 1rem;

                .result {
                    text-align: left;
                    flex-basis: calc(50% - .5rem);

                    .title {
                        font-size: 12px;
                        line-height: 21px;
                        margin: 0;
                    }

                    .result__data {
                        margin: 0;
                        font-weight: 700;

                    }

                    .note__data {
                        min-height: 19px;

                    }

                    .part-manufacturer__data {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                .part-description {
                    flex-basis: 100%;
                }

                .note {
                    flex-basis: 100%;
                }
            }
        }
    }

    .total-summary {
        border: 1px solid #5B5B5B;
        text-align: left;
        padding: 2rem 1rem 0;
        margin-bottom: 1rem;
        p {
            margin: 0;
        }

        div {
            display: flex;
            column-gap: 1rem;
            margin-bottom: 1rem;

            .title {
                min-width: 95px;
                max-width: 95px;
                font-size: 12px;
                line-height: 21px;
            }

            .total {
                font-weight: 700;
                color: #A91108;
            }

            .shipping-container {
                flex-wrap: wrap;
                row-gap: 1rem;
            }

            .shipping-blurb {
                font-weight: 700;
                color: #A91108;
            }

            .shipping-teaser {
                font-weight: 700;

                span {
                    color: #A91108;
                }
            }
        }
    }

    .shipping-summary {
        border: 1px solid #5B5B5B;
        text-align: left;
        padding: 2rem 1rem;
        margin-bottom: 3rem;
        p {
            margin: 0;
        }

        div {
            display: flex;
            column-gap: 1rem;
            margin-bottom: 1rem;
        }
        .shipping-container {
            font-weight: 700;
        }
        .title {
            min-width: 95px;
            max-width: 95px;
            font-size: 12px;
            line-height: 21px;
        }
        .standalone {
            margin-bottom: .5rem;
        }
        .address-container {
            flex-wrap: wrap;
            margin: 0;
            p {
                text-transform: capitalize;
            }
            p.name {
                font-weight: 700;
                margin-bottom: 0.5rem;
            }
        }

    }

    .new-account-container {
        display: flex;
        flex-wrap: nowrap;
        column-gap: 1rem;
        margin-top: 3rem;
        margin-bottom: 1rem;
        justify-content: center;
        p {
           text-align: left;
           max-width: 26.25rem;
        }
    }


    .button-group {
        flex-basis: 100%;
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        .link-wrapper {
            flex-basis: calc(50% - .5rem);
            width: calc(50% - .5rem);
            .link-container {
                width: 100%;
            }
        }
        button {
            flex-basis: calc(50% - .5rem);
            width: calc(50% - .5rem);
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-weight: 700;
            letter-spacing: 0.96px;
        }
    }
}


@media screen and (min-width: 768px) {
    .order-summary {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        justify-content: center;
        > * { 
            flex-basis: 100%;
        }
        .cart-accordion {
            .accordion__item {
                .accordion__heading {
                    padding: 2rem 2rem;
                    &.expanded {
                        padding: 2rem 2rem 1rem;
                    }
                }
                .accordion__panel:not([hidden]) {
                    .result {
                        flex-basis: calc(20% - 1rem);
                    }
                    .part-description {
                        flex-basis: 100%;
                    }

                }
            }
        }


       .total-summary, .shipping-summary {
        flex-basis: calc(50% - 2.5rem);
        max-width: calc(50% - 2.63rem);
        margin-bottom: 0;
       }
       .shipping-summary {
        .address-container {
            p {
                flex-basis: 100%;
            }
        }
       }
       .button-group {
        margin-top: 3rem;
        max-width: 40%;
       }
    }
}

@media screen and (min-width: 1024px) {
    .order-summary {
        > p {
            margin: 0;
        }
        p.company-name {
            margin-bottom: 1rem;
        }
        
        p.po {
            margin-bottom: 1rem;
        }
        p.special-instructions {
            margin-bottom: 2rem;
        }


        .cart-summary {
            border: 1px solid #5B5B5B;
            margin-bottom: 1rem;
            .cart-header {
                display: flex;
                flex-wrap: wrap;
                padding: 1rem 1rem 0;
                column-gap: 1rem;
                position: relative;
                .header-item {
                    text-align: left;
                    margin: 0;
                    font-size: 12px;
                }
                .part-number {
                    min-width: 15%;
                    max-width: 15%;
                }
                .part-description {
                    min-width: 20%;
                    max-width: 20%;
                }
                .note {
                    min-width: 20%;
                    max-width: 20%;
                }
                .quantity {
                    min-width: 5.5%;
                    max-width: 5.5%;
                }
                .price {
                    min-width: 13%;
                    max-width: 13%;
                }
                .dealer-price {
                    min-width: 7.5%;
                    max-width: 7.5%;
                }
                .subtotal {
                    min-width: 8.25%;
                    max-width: 8.25%;
                }
            }
            .result__container {
                flex-basis: 100%;
                padding: 0 1rem;
                &:first-of-type {
                    padding-top: 1rem;
                }
                padding-bottom: 1rem;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                row-gap: 1rem;
                column-gap: 1rem;
                
                .result__data {
                    margin: 0;
                    text-align: left;
                    font-weight: bold;
                }
                .part-manufacturer__data {
                    font-size: 12px;
                    font-weight: 400;
                }
                .part-number {
                    min-width: 15%;
                    max-width: 15%;
                }
                .part-description {
                    min-width: 20%;
                    max-width: 20%;
                }
                .note {
                    min-width: 20%;
                    max-width: 20%;
                }
                .quantity {
                    min-width: 5.5%;
                    max-width: 5.5%;
                }
                .price {
                    min-width: 13%;
                    max-width: 13%;
                }
                .dealer-price {
                    min-width: 7.5%;
                    max-width: 7.5%;
                }
                .subtotal {
                    min-width: 8.25%;
                    max-width: 8.25%;
                }
            }
        }
        .shipping-summary {
            display: flex;
            flex-wrap: wrap;
            column-gap: 1rem;
            .title {
                flex: 1 0 30%;
                min-width: 30%;
            }
            .shipping-container, .address-container {
                flex: 3 0 60%;
                min-width: 60%;
            }
        }
        .button-group {
            margin-top: 1.5rem;
        }
        
    }
    
}

@media screen and (min-width:1440px) {
    .order-summary {
        padding-left: 4rem;
        padding-right: 4rem;
        .cart-summary {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 2rem 0;
            .cart-header {
                width: 100%;
                justify-content: center;
                .part-description {
                    min-width: 24%;
                    max-width: 24%;
                }
                .subtotal {
                    min-width: 6%;
                    max-width: 6%;
                }
            }
            .cart-body {
                width: 100%;
                justify-content: center;
                display: flex;
                flex-wrap: wrap;

                .result__container {
                    justify-content: center;
                    .part-description {
                        min-width: 24%;
                        max-width: 24%;
                    }
                    .subtotal {
                        min-width: 6%;
                        max-width: 6%;
                    }
                }
            }
        }
        .shipping-summary {
            display: flex;
            flex-wrap: wrap;
            column-gap: 1rem;
            .title {
                flex: 1 0 20%;
                min-width: 20%;
            }
            .shipping-container, .address-container {
                flex: 3 0 70%;
                min-width: 70%;
            }
        }
        .total-summary, .shipping-summary {
            padding: 2rem;
            flex-basis: calc(50% - 4rem);
            max-width: calc(50% - 4.63rem);
        }
    }
    
}

@media screen and (min-width:1920px) {
    .order-summary {
        padding-left: 6rem;
        padding-right: 6rem;
        .total-summary {
            .shipping-container {
                .shipping-blurb {
                    flex-basis: 100%;
                }
            }
        }
        .shipping-summary {
            display: flex;
            flex-wrap: wrap;
            column-gap: 1rem;
            .title {
                flex: 1 0 15%;
                min-width: 15%;
            }
            .shipping-container, .address-container {
                flex: 3 0 70%;
                min-width: 70%;
            }
        }
    }
    
}