@media screen {
    .print-only {
        display: none;
    }
}
@media print {
    .print-only {
        display: block;
    }
    .print-logo {
        position: absolute;
    }
    .header-container, .app-footer, .mini-cart  {
        display: none;
    }  
    main {
        padding-top: 0 !important;
    } 
    .invoice-history-detail {
        margin-left: -2rem;
        margin-right: -2rem;
        .link-wrapper {
            display: none;
            margin-bottom: 2rem;
            &.d-flex {
                display: none !important;
            }
        }
        .address-container {
            display: flex;
            justify-content: center;
            column-gap: 4rem;
        }
        .invoice-container {
            //border: 1px solid #000;
            padding: 1rem 0rem;
            row-gap: 0;
            .invoice-header {
                border: 1px solid #000;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                text-align: left;
                font-size: 0.75rem;
                padding: 0rem 1rem;
                .part-number {
                    min-width: 14%;
                    max-width: 14%;
                    padding-right: 1rem;
                    box-sizing: border-box;
                }
                .part-description {
                    min-width: 28%;
                    max-width: 28%;
                }
                .price {
                    min-width: 18%;
                    max-width: 18%;
                }
                .dealer-price {
                    min-width: 15%;
                    max-width: 15%;
                }
                .quantity {
                    min-width: 9%;
                    max-width: 9%;
                }
                .quantity_shipped {
                    min-width: 8%;
                    max-width: 8%;
                }
                .quantity_backordered {
                    min-width: 12%;
                    max-width: 12%;
                }
                .subtotal {
                    min-width: 9%;
                    max-width: 9%;
                }
            }
            .result__container {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                text-align: left;
                font-weight: 700;
                border: 1px solid #000;
                padding: 0rem 1rem;
                .part-number {
                    min-width: 14%;
                    max-width: 14%;
                    padding-right: 1rem;
                    box-sizing: border-box;
                }
                .part-description {
                    min-width: 28%;
                    max-width: 28%;
                    .part-description__data {
                        padding-right: 1rem;
                    }
                }
                .price {
                    min-width: 18%;
                    max-width: 18%;
                }
                .dealer-price {
                    min-width: 15%;
                    max-width: 15%;
                }
                .quantity {
                    min-width: 9%;
                    max-width: 9%;
                }
                .quantity_shipped {
                    min-width: 8%;
                    max-width: 8%;
                }
                .quantity_backordered {
                    min-width: 12%;
                    max-width: 12%;
                }
                .subtotal {
                    min-width: 9%;
                    max-width: 9%;
                }
            }
        }
        .box-container {
            display: flex;
            justify-content: center;
            column-gap: 1.25rem;
            flex-wrap: nowrap;
            .cost-container {
                border: 1px solid #000;
                display: flex;
                flex-basis: 70%;
                justify-content: space-evenly;
                .cost {
                    flex-basis: auto;
                    column-gap: 1rem;
                    .title {
                        white-space: pre;

                    }
                }
            }
            .total-container {
                border: 1px solid #000;
                display: flex;
                flex-basis: 30%;
                flex-wrap: wrap;
                .title, .value {
                    flex-basis: 100%;
                    text-align: center;
                    .due {
                        margin-top: .5rem;
                    }
                }
            }
        }
    }


}