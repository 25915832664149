h1 {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 12px;
    color: #5b5b5b;

    &::after {
        content: " ";
        display: flex;
        width: 76px;
        max-width: 76px;
        height: 1px;
        background-color: #5b5b5b;
        //flex-basis: 100%;
    }
}

h2 {
    margin-bottom: .5rem;
    font-size: 24px;
    color: #5B5B5B;
    font-weight: 700;
    line-height: 36px;
}

p {
    word-break: break-word;
    &.bold {
        font-weight: 700;
    }
}


.link-wrapper {
    display: flex;
    justify-content: center;
    p.link-container.button-primary {
        flex-basis: auto;
        a {
            color: #fff;
            border-bottom: unset;
        };
        svg {
            color: currentColor;
        }
        &:hover {
            a {
                color: #fff;
            };
            svg {
                color: currentColor;
            }
        }
    }
    p.link-container.button-secondary {
        flex-basis: auto;
        a {
            color: #fff;
            border-bottom: unset;
        };
        svg {
            color: currentColor;
        }
        &.no-hover {
            &:hover {
                background-color: #5B5B5B;
            }
        }
        &:hover {
            a {
                color: #fff;
            };
            svg {
                color: currentColor;
            }
        }
    }
    p.link-container {
        cursor: pointer;
        width: fit-content;
        display: flex;
        align-items: center;
        column-gap: .5rem;
        a.icon {
            border-bottom: unset;
        }
        svg { 
            color: #A91108;
        }
        &:hover {
            svg {
                color: #000;
            }
            a {
                color: #000;
            }
        }
    }
}

a {
    color: #A91108;
    padding-bottom: 2px;
    border-bottom: 1px solid #A91108;
    text-decoration: none;
    &:hover {
        color: #000;
        border-color: #000;
    }
}


.button {
    display: flex;
    flex-basis: 100%;
    border-radius: 6px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    letter-spacing: 0.96px;
    line-height: 21px;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 700;   
    align-items: center;
}

button.button {
    border: unset;
    margin-left: auto;
    margin-right: auto;
    flex-basis: 100%;
    width: 100%;
}

.button-primary {
    background-color: #A91108;
    color: #fff;
    border: unset;
    
    &:hover {
        color: #fff;
        background-color: #000;
    }
}

.button-secondary {
    background-color: #5B5B5B;
    color: #fff;
    border: unset;
    &:hover {
        background-color: #000;
    }
}

.button-outline {
    border: 1px solid #A91108;
    background-color: transparent;
    text-transform: uppercase;
    &:hover {
        border: 1px solid #000;
        color: #fff;
        background-color: #000;
    }
}


form {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    margin-bottom: 1rem;
    .field-container {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 100%;
        label, input {
            flex: 1 1 100%;
        }
        label {
            text-align: left;
            margin-bottom: .5rem;
        }
        input {
            border: 1px solid #000;
            border-radius: 6px;
            padding: .5rem;
        }
        .error {
            color: #A91108;
            font-weight: bold;
            margin-bottom: .5rem;
        }
        .error ~ input {
            border-color: #A91108;

        }
    }
    button {
        flex: 1 1 100%;
        background-color: #A91108;
        border: 1px solid #A91108;
        color: #fff;
        text-transform: uppercase;
        padding: .5rem 2rem;
        border-radius: 6px;
        &:hover {
            background-color: #000;
            border-color: #000;
        }
    }
}

.d-flex {
    display: flex !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-0 {
    margin-top:  0 !important;
}

.mt-1 {
    margin-top:  1rem !important;
}

.mt-2 {
    margin-top:  2rem !important;
}

.mt-3 {
    margin-top:  3rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}
.mb-2 {
    margin-bottom: 2rem !important;
}
.mb-3 {
    margin-bottom: 3rem !important;
}

.confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    p {
        width: 70%;
        margin: 0;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

@media screen and (min-width: 768px) {
    .confirmation {
        p {
            margin-bottom: 2rem;
        }
        .button {
            max-width: 40%;
        }
    }
}