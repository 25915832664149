.user-management {
    > .button {
        margin-top: 1rem;
    }
    .button-group {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        row-gap: 1rem;
        > .button {
            flex-basis: 100%;
        }
    }
}

.create-user {
    .field-container {
        display: flex;
        label {
            display: flex;
            justify-content: flex-start;
        }
    }
    
}

.manage-user {
    .user-data {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        > * {
            flex-basis: 100%;
        }
        justify-content: center;
        p {
            margin: .5rem 0;
        }
        
    }
}

@media screen and (min-width: 768px) {
    .user-management, .create-user, .manage-user {
        padding-left: 12rem;
        padding-right: 12rem;
    }

}

@media screen and (min-width: 1024px) {
    .user-management, .create-user, .manage-user {
        padding-left: 16rem;
        padding-right: 16rem;
        p.bold {
            white-space: nowrap;
        }
        .button {
            white-space: nowrap;
            max-width: 65%;
            margin-left: auto;
            margin-right: auto;
        }
    }

}

@media screen and (min-width: 1440px) {
    .user-management, .create-user, .manage-user {
        padding-left: 32rem;
        padding-right: 32rem;
        .button {
            max-width: 80%;
        }
    }
}

@media screen and (min-width: 1920px) {
    .user-management, .create-user, .manage-user {
        padding-left: 46.125rem;
        padding-right: 46.125rem;
    }
}